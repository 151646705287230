import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ArticleExcerpt from './ArticleExcerpt';

function HomeArticles() {
  const appBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [topArticles, setTopArticles] = useState([]);
  const [latestArticles, setLatestArticles] = useState([]);
  const [middleArticles, setMiddleArticles] = useState([]);
  const [bottomArticles, setBottomArticles] = useState([]);
  const [loading, setLoading] = useState({
    latest: true,
    top: true,
    middle: true,
    bottom: true,
  });
  const [limit, setLimit] = useState(3); // State for limit

  const fetchData = async () => {
    setLoading((prev) => ({ ...prev, latest: true }));
    try {
      const latestResults = await Axios.get(
        `${appBaseUrl}/api/articles/latest`,
        {
          params: { limit },
        }
      );
      setLatestArticles(latestResults?.data);
      setLoading((prev) => ({ ...prev, latest: false }));
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      setLoading((prev) => ({ ...prev, latest: false }));
    }

    setLoading((prev) => ({ ...prev, top: true }));
    try {
      const tpResults = await Axios.get(`${appBaseUrl}/api/articles/`, {
        params: { categoryId: '668ac52d13e122aa76edaa8b', limit },
      });
      setTopArticles(tpResults?.data);
      setLoading((prev) => ({ ...prev, top: false }));
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      setLoading((prev) => ({ ...prev, top: false }));
    }

    setLoading((prev) => ({ ...prev, middle: true }));
    try {
      const mdResults = await Axios.get(`${appBaseUrl}/api/articles/`, {
        params: { categoryId: '668ac5ca13e122aa76edaa8d', limit },
      });
      setMiddleArticles(mdResults?.data);
      setLoading((prev) => ({ ...prev, middle: false }));
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      setLoading((prev) => ({ ...prev, middle: false }));
    }

    setLoading((prev) => ({ ...prev, bottom: true }));
    try {
      const btResults = await Axios.get(`${appBaseUrl}/api/articles/`, {
        params: { categoryId: '668ac5d413e122aa76edaa8f', limit },
      });
      setBottomArticles(btResults?.data);
      setLoading((prev) => ({ ...prev, bottom: false }));
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      setLoading((prev) => ({ ...prev, bottom: false }));
    }
  };

  useEffect(() => {
    setLimit(3);
    fetchData();
  }, []);

  const renderArticles = (articles) => {
    return articles.map((article, i) => (
      <Col md={4} key={i}>
        <Card>
          <div className="image-container">
            <Card.Img
              variant="top"
              src={article?.imageUrl}
              alt={article.name}
            />
          </div>
          <Card.Body>
            <Card.Title>{article?.name}</Card.Title>
            <ArticleExcerpt description={article?.description} />
            <Link className="read-more" to={`/articles/${article._id}`}>
              <Button variant="primary">Read More</Button>
            </Link>
          </Card.Body>
        </Card>
      </Col>
    ));
  };

  return (
    <div className="home articles">
      <h2>Latest Stories</h2>
      <Row className="my-4 articles">
        {loading.latest ? (
          <Col className="text-center">
            <Spinner animation="border" />
          </Col>
        ) : (
          renderArticles(latestArticles)
        )}
      </Row>
      <h2>Editor's Choice</h2>
      <Row className="my-4 articles">
        {loading.top ? (
          <Col className="text-center">
            <Spinner animation="border" />
          </Col>
        ) : (
          renderArticles(topArticles)
        )}
      </Row>
      <h2>Apna Gyaan</h2>
      <Row className="my-4 articles">
        {loading.middle ? (
          <Col className="text-center">
            <Spinner animation="border" />
          </Col>
        ) : (
          renderArticles(middleArticles)
        )}
      </Row>
      <h2>Parenting</h2>
      <Row className="my-4 articles">
        {loading.bottom ? (
          <Col className="text-center">
            <Spinner animation="border" />
          </Col>
        ) : (
          renderArticles(bottomArticles)
        )}
      </Row>
    </div>
  );
}

export default HomeArticles;
