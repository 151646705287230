import React, { useContext } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Store } from '../../Store';

function Header() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { userInfo, categories } = state;

  const singoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' });
    localStorage.removeItem('userInfo');
  };
  return (
    <Navbar bg="light" expand="lg" className="header lekh">
      <Container>
        <Navbar.Brand to="/">
          <Nav.Link as={Link} to="/">
            <h1 style={{ padding: '0px', margin: '0px' }}>
              <img
                src="/images/lekh-junction-logo.png"
                className="logo"
                alt="lekh-junction-logo"
              />
            </h1>
          </Nav.Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/about-us">
              About Us
            </Nav.Link>
            <NavDropdown title="Categories" id="basic-nav-dropdown">
              {categories.map((category, index) => (
                <NavDropdown.Item
                  as={Link}
                  to={`/category/${category._id}`}
                  key={index}
                >
                  {category.name}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
            <Nav.Link as={Link} to="/contact-us">
              Contact Us
            </Nav.Link>
            {userInfo ? (
              <NavDropdown title={userInfo?.name} id="basic-nav-dropdown">
                <NavDropdown.Item as={Link} to="/profile">
                  User Profile
                </NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/admin/dashboard">
                  Dashboard
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item
                  as={Link}
                  to="#signout"
                  onClick={singoutHandler}
                >
                  Sign Out
                </NavDropdown.Item>
              </NavDropdown>
            ) : (
              <Nav.Link as={Link} to="/login">
                Sign In
              </Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
